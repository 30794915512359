import { Page } from "./Page";
import { PlayerHandler } from "../libs/Player";
import lightGallery from "lightgallery";
import { Votes } from "../libs/Votes";
import Swiper, { Autoplay } from "swiper";

export class VideoDetailPage extends Page {
  public async start(): Promise<any> {
    const player = document.querySelector("[data-player-get-settings-url]");
    if (player) {
      await new PlayerHandler(player as HTMLElement).init();
    }

    await super.start();

    const partiallyDisabledPlayer: HTMLElement = document.querySelector(
      "#player-wrapper.partially-disabled"
    );
    if (partiallyDisabledPlayer) {
      const target_url = partiallyDisabledPlayer.dataset.alternateVideoUrl;
      setTimeout(() => {
        window.location.href = target_url;
      }, 3000);
    }

    const picturesGallery: HTMLElement =
      document.getElementById("video-pictures");

    if (picturesGallery) {
      lightGallery(picturesGallery, {
        download: false,
        selector: "[data-gallery-item]",
      });
    }

    document
      .querySelectorAll("[data-stars-container] label")
      .forEach((labelElement: HTMLLabelElement) => {
        labelElement.addEventListener("click", async (e: Event) => {
          e.preventDefault();
          const inputElement = document.getElementById(
            labelElement.htmlFor
          ) as HTMLInputElement;
          if (inputElement) await new Votes(inputElement).vote();
        });
      });

    const actorsSlider: HTMLElement = document.getElementById("actors-slider");
    if (actorsSlider) {
      const swiper = new Swiper(actorsSlider, {
        modules: [Autoplay],
        autoplay: true,
        speed: 500,
        spaceBetween: 10,
        slidesPerView: 2,
        loop: false,
        preloadImages: true,

        breakpoints: {
          // when window width is >= 768px
          768: {
            centeredSlides: false,
            slidesPerView: 3.5,
          },
          // when window width is >= 920
          920: {
            centeredSlides: false,
            slidesPerView: 5.5,
          },
        },
      });

      swiper.init();

      // tns({
      //   container: actorsSlider,
      //   items: 2,
      //   slideBy: 1,
      //   autoplay: true,
      //   mouseDrag: true,
      //   touch: true,
      //   autoplayTimeout: 4000,
      //   rewind: true,
      //   nav: false,
      //   controls: false,
      //   autoplayButtonOutput: false,
      //   gutter: 10,
      //   lazyload: true,
      //   lazyloadSelector: ".lazyload",
      //   responsive: {
      //     768: {
      //       items: 3,
      //     },
      //
      //     920: {
      //       items: 5,
      //     },
      //
      //     1280: {
      //       items: 5,
      //     },
      //   },
      // });
    }
  }
}
