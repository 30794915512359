import { Disclaimer } from "@tawenda-npm/tawenda-disclaimer";
import "lazysizes";
import { Popunder } from "@tawenda-npm/tawenda-popunder";
import { UserState } from "../libs/UserState";
import lazyFrame from "../libs/lazyFrame";
import { DropDownHandler } from "../libs/DropDown";
import { FidelityOffer } from "@tawenda-npm/tawenda-fidelity-offer";
import initBackButton from "@tawenda-npm/tawenda-back-button";
import { caughtPromiseAll } from "../libs/promises";
import searchHandler from "../libs/Search";
import affiliationPixel from "../libs/AffiliationPixel";
import initNextUrls from "../libs/nextUrl";
import { AdBlock } from "@tawenda-npm/tawenda-adblock";
import { PlansQ } from "@tawenda-npm/tawenda-plansq";
import { LikeActor } from "../libs/LikeActor";
import DalenysSubscription from "../libs/DalenysSubscription";

export class Page {
  protected readonly htmlElement: HTMLHtmlElement;
  protected readonly disclaimer: Disclaimer;
  protected readonly popunderUrl: string;
  protected readonly popunder: Popunder;
  protected readonly dalenysModal: HTMLElement | undefined;
  protected readonly dalenysBanner: HTMLElement | undefined;

  constructor() {
    this.htmlElement = document.getElementsByTagName("html")[0];
    this.popunderUrl = document.documentElement.dataset.popunderUrl;
    this.popunder = new Popunder(this.popunderUrl);
    this.disclaimer = new Disclaimer();
    this.disclaimer.addEventListener("closed", async () => {
      await this.popunder.redirectToPopUnder();
    });
    this.dalenysModal = document.querySelector("[data-dalenys-modal]");
    this.dalenysBanner = document.querySelector("[data-dalenys-banner]");
  }

  public async start(): Promise<void> {
    const promisesToCall = [
      this.disclaimer.loadDisclaimer(),
      this.popunder.setupDOM(),
      new AdBlock(document.documentElement.getAttribute("lang")).showAegis(),
    ];

    const userStateHandler: HTMLElement = document.querySelector(
      "[data-user-state-url]"
    );
    if (userStateHandler) {
      promisesToCall.push(
        new UserState(userStateHandler.dataset.userStateUrl).setupDOM()
      );
    }

    await caughtPromiseAll(promisesToCall);
    lazyFrame();
    initBackButton(this.popunderUrl);
    initNextUrls();
    searchHandler();

    const plansQDesktop: HTMLElement = document.querySelector(
      "[data-plans-q-desktop]"
    );
    if (plansQDesktop) new PlansQ(plansQDesktop).show();

    const plansQMobile: HTMLElement = document.querySelector(
      "[data-plans-q-mobile]"
    );
    if (plansQMobile) new PlansQ(plansQMobile).show();

    if (this.dalenysModal || this.dalenysBanner) {
      new DalenysSubscription(this.dalenysBanner ?? this.dalenysModal).show();
    }

    const mobileMenuToggle = document.getElementById("header-mobile-toggle"),
      mobileNavbar = document.querySelector("[data-navbar-mobile]");

    mobileMenuToggle.addEventListener("click", function () {
      mobileNavbar.classList.toggle("header--open");
    });

    document
      .querySelectorAll("[data-like-actor]")
      .forEach((el: HTMLButtonElement) => {
        new LikeActor(el);
      });

    const dropdownHandlersIds = [
      "dropdown-user",
      "dropdown-filter-type",
      "dropdown-filter-categories",
      "dropdown-filter-actress",
      "dropdown-filter-studios",
      "dropdown-ordering",
      "dropdown-filter-hair",
      "dropdown-filter-breast",
      "dropdown-filter-details",
      "dropdown-footer-languages",
      "dropdown-download",
    ];
    dropdownHandlersIds.forEach((id: string) => {
      const dropDown = document.getElementById(id);
      if (dropDown) {
        new DropDownHandler(dropDown);
      }
    });

    document
      .querySelectorAll("[data-background-image]")
      .forEach((el: HTMLElement) => {
        el.style.backgroundImage = `url('${el.dataset.backgroundImage}')`;
      });

    const fidelityOfferWrapper: HTMLElement = document.querySelector(
      "[data-widget-come-back]"
    );
    if (fidelityOfferWrapper) {
      const fidelityOffer = new FidelityOffer({
        element: fidelityOfferWrapper,
        endpoints: {
          create: document.documentElement.dataset.fidelityOfferUrlCreate,
          update: document.documentElement.dataset.fidelityOfferUrlUpdate,
          check: document.documentElement.dataset.fidelityOfferUrlCheck,
        },
      });
      await fidelityOffer.init();
    }

    affiliationPixel();
  }
}
