import { TrailerAd } from "./TrailerAd";
import { toggleClass } from "@tawenda-npm/tawenda-utils";

interface settingsInterface {
  has_global_subscription: boolean;
  can_stream: boolean;
  can_download: boolean;
  video_settings_url: URL | null;
}

interface sourcesInterface {
  label: string;
  src: URL;
  type: string;
}

interface downloadsInterface {
  file: URL;
  label: string;
  size?: number;
}

interface playerSourcesInterface {
  downloads: Array<downloadsInterface>;
  streams: Array<sourcesInterface>;
  vtt: URL;
}

export class PlayerHandler {
  player: any;
  settings: settingsInterface;
  playerVideo: HTMLVideoElement | null;
  playerWrapper: HTMLDivElement | null;
  playerPoster: HTMLImageElement | null;
  playerPlayButton: HTMLButtonElement | null;
  playerBannerText: string;
  defaultPurchaseUrl: string;
  movieCover: HTMLDivElement | null;
  logo4k: HTMLImageElement | null;

  constructor(private trigger: HTMLElement) {
    this.settings = {
      has_global_subscription: false,
      can_stream: false,
      can_download: false,
      video_settings_url: null,
    };

    this.playerVideo = document.querySelector("[data-player-video]");
    this.playerWrapper = document.querySelector("[data-player-wrapper]");

    this.playerPoster = document.getElementById(
      "video-player-poster"
    ) as HTMLImageElement;

    this.playerPlayButton =
      this.playerWrapper.querySelector("[data-play-button]");

    this.playerBannerText =
      this.trigger.dataset.playerBannerText ?? "Voir la vidéo complète";

    this.movieCover = document.querySelector(
      "[data-movie-cover]"
    ) as HTMLDivElement;

    this.logo4k = document.querySelector("[data-logo-4k]") as HTMLImageElement;
  }

  public async init(): Promise<any> {
    const url = this.trigger.dataset.playerGetSettingsUrl;
    if (!url) return;

    const response = await fetch(url);

    if (response.ok) {
      this.settings = await response.json();

      this.setupDOM();

      if (!this.settings.video_settings_url) {
        this.playerPlayButton.addEventListener("click", (e: Event) => {
          e.preventDefault();
          PlayerHandler.openStraceo();
        });

        return;
      }

      this.playerWrapper?.classList.remove("nsfw");

      await this.setupPlayer().then(() => this.initEvents());
    }
  }

  private setupDOM(): void {
    if (this.settings.has_global_subscription) {
      document
        .querySelectorAll("[data-hide-if-user-has-global-subscription]")
        .forEach((el: Element) => {
          el.remove();
        });
    }

    if (this.settings.can_stream) {
      document.body.dataset.userCanStream = "1";

      document
        .querySelectorAll("[data-hide-if-user-can-stream]")
        .forEach((el: Element) => {
          el.remove();
        });
      document
        .querySelectorAll("[data-show-if-user-can-stream]")
        .forEach((el: Element) => {
          (el as HTMLElement).style.display = "block";
        });

      if (this.settings.can_download) {
        document
          .querySelectorAll("[data-show-if-user-can-download]")
          .forEach((el: Element) => {
            (el as HTMLElement).style.display = "contents";
          });
      }
    } else {
      document
        .querySelectorAll("[data-show-if-user-can-stream]")
        .forEach((el: Element) => {
          el.remove();
        });
    }
  }

  private async setupPlayer(): Promise<any> {
    const sources: playerSourcesInterface = await (
      player as any
    ).Player.getSources(this.settings.video_settings_url);

    const bannerOptions = {
      enabled: !this.settings.can_stream,
      text: this.playerBannerText,
    };

    this.player = new (player as any).Player(
      "video-player",
      sources.streams,
      sources.vtt,
      {
        banner: bannerOptions,
        accessType: this.settings.can_stream ? "premium" : "freemium",
      }
    );

    if (this.settings.can_download) {
      this.setupDownloadButtons(sources.downloads);
    }
  }

  private setupDownloadButtons(downloads: Array<downloadsInterface>): void {
    document
      .querySelectorAll("[data-hide-if-user-can-download]")
      .forEach((el: Element) => {
        el.remove();
      });

    const downloadLinksWrapper = document.querySelector(
      "[data-download-urls-container]"
    ) as HTMLDivElement;

    const downloadLinksList = document.querySelector(
      "[data-download-urls]"
    ) as HTMLUListElement;

    const voidDownloadItem = downloadLinksList.querySelector("li");

    downloads.forEach((download: downloadsInterface) => {
      const downloadItem = voidDownloadItem.cloneNode(true) as HTMLLIElement;

      const downloadItemTitle = downloadItem.querySelector(
        "[data-download-title]"
      );
      if (downloadItemTitle) downloadItemTitle.textContent = download.label;

      const downloadItemLink = downloadItem.querySelector("a");
      downloadItemLink.setAttribute(
        "href",
        PlayerHandler.getDownloadUrl(download)
      );

      downloadLinksList.prepend(downloadItem);
    });
    voidDownloadItem.remove();
    downloadLinksWrapper.style.display = "block";
  }

  private static getDownloadUrl(download: downloadsInterface): string {
    const videoSlugElement: HTMLElement =
      document.querySelector("[data-video-slug]");

    return `${download.file}&download=1&filename=${download.label}-${videoSlugElement?.dataset.videoSlug}`;
  }

  private initEvents(): void {
    const straceoCta = document.querySelector(
      "[data-straceo-cta]"
    ) as HTMLAnchorElement;

    this.player.on("ready", () => {
      const videoJsEl = document.querySelector(
        ".video-player .video-js"
      ) as HTMLElement;
      if (videoJsEl) {
        videoJsEl.style.display = "block";
      }
      this.playerPlayButton?.remove();
      this.playerPoster?.remove();
    });

    if (!this.settings.can_stream) {
      this.player.on("play", () => {
        if (straceoCta) {
          straceoCta.style.display = "none";
        }
        const trailerAd = new TrailerAd(this.player);
        const videoIdElement: HTMLElement =
          document.querySelector("[data-video-id]");
        const video_id = videoIdElement?.dataset.videoId;
        if (video_id) trailerAd.check(parseInt(video_id));
      });

      this.player.on("clickBanner", () => {
        if (this.player.player.isFullscreen()) {
          this.player.player.exitFullscreen();
        }

        this.player.pause();
        PlayerHandler.openStraceo();
        if (document.body.dataset.page === "video-detail-embed") {
          window.open(window.location.href.replace("/embed/", "/show/"));
        }
      });

      this.player.on("ended", () => {
        if (straceoCta) {
          straceoCta.style.display = "block";
        }
      });
    }

    this.player.on("play", () => {
      if (this.movieCover) {
        this.movieCover.style.display = "none";
        this.movieCover.classList.remove("video-player__poster--video-paused");
      }
      if (this.logo4k) this.logo4k.style.display = "none";
    });
    this.player.on("pause", () => {
      if (this.movieCover) {
        this.movieCover.style.display = "block";
        this.movieCover.classList.add("video-player__poster--video-paused");
      }
      if (this.logo4k) this.logo4k.style.display = "block";
    });
  }

  private static openStraceo(): void {
    const straceoHandler: HTMLAnchorElement = document.querySelector(
      "[data-default-purchase-url]"
    );
    if (straceoHandler) {
      window.open(straceoHandler.href, "_blank");
    }
  }
}
