import { Page } from "./Page";
import Swiper, { Autoplay, Pagination } from "swiper";
import { Tabs } from "@tawenda-npm/tawenda-utils/lib/tabsLib";

export class HomePage extends Page {
  public async start(): Promise<void> {
    const carouselBanners: HTMLElement = document.getElementById(
      "home-top-banners-slider"
    );
    if (carouselBanners) {
      new Swiper(carouselBanners, {
        modules: [Autoplay, Pagination],
        autoplay: true,
        speed: 500,
        spaceBetween: 20,
        slidesPerView: 1.25,
        centeredSlides: true,
        centeredSlidesBounds: true,
        loop: true,
        preloadImages: true,

        pagination: {
          el: ".swiper-pagination",
        },
      });
    }
    await super.start();

    const tabsWrapper: HTMLElement = document.querySelector(
      "[data-tabs-wrapper]"
    );
    if (tabsWrapper) {
      new Tabs(tabsWrapper);
    }
  }
}
